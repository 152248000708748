<template>
  <b-card class="" style="min-height: 10em;">
    <div v-if="!$isEmpty(user)" class="w-100 h-100">
      <div class="text-center">
        <h3
          class="font-weight-bold cursor-pointer"
        >
          {{ user.fullname || '-' }}
        </h3>
        <h5>{{ user.company_name || '-' }}</h5>
      </div>

      <div class="mt-2">
        <h5>Contact Information</h5>
        <ul class="list-unstyled pl-1">
          <li class="">
            <feather-icon
              icon="MailIcon"
              size="16"
              class="mr-75 text-primary"
            />
            <span class="align-middle">{{ user.email_address || '-' }}</span>
          </li>
          <li class="">
            <feather-icon
              icon="PhoneCallIcon"
              size="16"
              class="mr-75 text-primary"
            />
            <span class="align-middle">{{ user.phone_number || '-' }}</span>
          </li>
        </ul>
      </div>
    </div>

    <div v-if="!$isEmpty(contactDetails._id)" class="mt-1">
      <h5> Temporary Contact Details </h5>
      <div class="mx-1">
        <div>
          <label>Name</label>
          <b-input v-model="contactDetails.fullname" />
        </div>
        <div>
          <label>Phone Number</label>
          <b-input v-model="contactDetails.phone_number" />
        </div>
        <div>
          <label>Email</label>
          <b-input v-model="contactDetails.email" />
        </div>

        <b-button
          class="mt-1 float-right"
          variant="primary"
          @click="put_temporary_contact_details"
        >Update Contact Details</b-button>
      </div>
    </div>
  </b-card>
</template>

<script>
export default {
  props: {
    user: { type: Object, default: () => {} },
    temporaryContactDetails: { type: Object, default: () => {} },
  },
  computed: {
    contactDetails() {
      return this.temporaryContactDetails || {}
    },
  },
  methods: {
    put_temporary_contact_details() {
      this.$http
        .put(`/api/help-desks/issues-temporary-contact-details`, {
          form_data: this.contactDetails
        })
        .then(response => {
          this.contactDetails = response.data.output
          this.$emit('updated-temporary-contact-details', true)

          this.$toast(
            {
              component: this.$toastContent,
              props: {
                title: 'Operation Success',
                icon: 'CheckCircleIcon',
                text: 'Temporary contact details successfully updated',
                variant: 'success',
              },
            },
            { timeout: this.$longestTimeout },
          )
 
        })
        .catch(() => {})
    },
  },
}
</script>

<style>

</style>