<template>
  <section class="h-100 m-0 p-0">
    <!-- Header -->
    telegram

    <!-- Send Chat -->
  </section>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  methods: {
    get_chat_history(isLoading = true) {
      return new Promise((resolve, reject) => {
        this.isLoading = isLoading

        // server pagination

        this.$http
          .get(`/api/telegram/get-chat-history`)
          .then(response => {
            response.data.output.forEach(chat => {
              // console.log(JSON.parse(chat))
            })

            this.isLoading = false

            resolve()
          })
          .catch(() => {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Operation Failed',
                  icon: 'CheckCircleIcon',
                  text: 'Could not retrieve the ticket list. Please try again later!',
                  variant: 'danger',
                },
              },
              { timeout: this.$longestTimeout },
            )

            this.isLoading = false

            reject()
          })

        // this.$http
        //   .get(`/api/telegram/get-dialogs`)
        //   .then(response => {
        //     response.data.output.forEach(chat => {
        //       console.log(JSON.parse(chat))
        //     })
        //     console.log(`🚀 ~ file: HelpDesks.vue ~ line 565 ~ returnnewPromise ~ response`, response)

        //     this.isLoading = false
        //     resolve()
        //   })
        //   .catch(() => {
        //     this.$toast(
        //       {
        //         component: this.$toastContent,
        //         props: {
        //           title: 'Operation Failed',
        //           icon: 'CheckCircleIcon',
        //           text: 'Could not retrieve the ticket list. Please try again later!',
        //           variant: 'danger',
        //         },
        //       },
        //       { timeout: this.$longestTimeout },
        //     )

        //     this.isLoading = false

        //     reject()
        //   })
      })
    },
  },
}
</script>

<style>

</style>